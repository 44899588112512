let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class Nodes {
    #source_data_model = null;

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
            throw "Nodes - constructor - Cannot construct singleton";
        }
    }

    static get_instance() {
        // console.log("Nodes - get instance");
        if (!this[singleton]) {
            this[singleton] = new Nodes(singletonEnforcer);
        }

        return this[singleton];
    }

    set_source_data_model(source_data_model) {
        this.#source_data_model = source_data_model;
    }

    get_all() {
        return this.#source_data_model.get();
    }

    get(node_name) {
        return this.#source_data_model.get_by_name(node_name);
    }

    is_node_empty(node_name) {
        if (node_name.length === 0) {
            return true;
        }

        const the_node = this.#source_data_model.get_by_name(node_name);

        if (!the_node) {
            throw new Error("Nodes - is_empty - this node doesn't exist : " + node_name);
        }
        if (!the_node.description) {
            return true;
        }

        return the_node.description.length === 0;
    }
}