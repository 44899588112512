import * as templates from "../../views/modules/hierarchy_tree/search";
import Nodes from "./Nodes";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class Search_bar {
    #dropdown_data = null;
    #search_bar_contenteditable_element = null;
    #nodes_to_search = [];
    #dropdown_items_elements = {};
    #dropdown_element = null;
    #search_controls_element = null;
    #selected_nodes_to_search_section_element = null;
    #selected_nodes_to_search_list_element = null;
    #dropdown_content_element = null;
    #open_selected_nodes_list_button_element = null; // For tablet and phone only
    #close_selected_nodes_list_button_element = null; // For tablet and phone only
    #isPhoneAndTabledResolution = false;
    #doMultiNodesSearch = false;
    #source_data_model_as_array = [];
    #oNodes = null;
    #is_search_in_use = false;

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
            throw "Search_bar - constructor - Cannot construct singleton";
        } else {
            this._is_placeholder_enabled = true;
        }
    }

    static get_instance() {
        // console.log("Search_bar - get instance");
        if (!this[singleton]) {
            this[singleton] = new Search_bar(singletonEnforcer);
        }

        return this[singleton];
    }

    prepare_oNodes() {
        this.#oNodes = Nodes.get_instance();
    }

    get_view() {
        return templates.search(this.#dropdown_data, this.#doMultiNodesSearch);
    }

    run() {
        this.#search_bar_contenteditable_element = document.getElementById("search_bar__contenteditable");
        this.#dropdown_element = document.getElementById("dropdown");
        this.#dropdown_content_element = document.getElementById("dropdown_content");

        document.getElementById("dropdown_content").querySelectorAll("button").forEach((button_element) => {
            this.#dropdown_items_elements[button_element.dataset.nodeName] = button_element;
        });

        this.#source_data_model_as_array = Object.entries(this.#oNodes.get_all());

        this.#search_controls_element = document.getElementById("search_bar__controls");
        this.#selected_nodes_to_search_section_element = document.getElementById("search_bar__selected_nodes");
        this.#selected_nodes_to_search_list_element = document.getElementById("search_bar__selected_nodes__list");
        this.#open_selected_nodes_list_button_element = document.getElementById("search_bar__selected_nodes__open_section_button");
        this.#close_selected_nodes_list_button_element = document.getElementById("search_bar__selected_nodes__close_section_button");

        this.listeners();
    }

    set_configuration(configuration) {
        this.#doMultiNodesSearch = configuration.doMultiNodesSearch || this.#doMultiNodesSearch;
    }

    empty_search_bar() {
        this.#search_bar_contenteditable_element.innerText = "";
    }

    add_node_to_search_list(item_element) {
        const node_name = item_element.dataset.nodeName;

        if (this.#nodes_to_search.includes(node_name)) {
            return;
        }

        this.empty_search_bar();

        item_element.classList.add("current");

        this.#nodes_to_search.push(node_name);

        this.#selected_nodes_to_search_list_element.insertAdjacentHTML("afterbegin", templates.selected_node_in_selected_nodes_section(node_name));
        document.querySelector(`.selected_node_to_search[data-node-name="${node_name}"]`).addEventListener("click", (event) => {
            this.remove_node_from_search_list(event.currentTarget);
        }, false);

        if (this.#nodes_to_search.length === 1) {
            this.#selected_nodes_to_search_section_element.classList.add("is-open");
        }
    }

    remove_node_from_search_list(item_element) {
        const node_name = item_element.dataset.nodeName;

        if (!this.#nodes_to_search.includes(node_name)) {
            return;
        }

        document.querySelector(`.selected_node_to_search[data-node-name="${node_name}"]`).remove();
        document.querySelector(`.dropdown__content button[data-node-name="${node_name}"]`).classList.remove("current");

        const index = this.#nodes_to_search.indexOf(node_name);

        this.#nodes_to_search.splice(index, 1);

        if (this.#nodes_to_search.length === 0) {
            if (this.#isPhoneAndTabledResolution) {
                this.close_search_list_for_tablet_and_phone_width();
            }

            this.#selected_nodes_to_search_section_element.classList.remove("is-open");
        }
    }

    open_search_blocks() {
        if (this._is_placeholder_enabled) {
            document.getElementById("search_bar_placeholder").remove();

            this._is_placeholder_enabled = false;
        }

        document.body.classList.add("no-scrollable");

        this.#search_bar_contenteditable_element.classList.add("is-open");
        this.#dropdown_element.classList.add("is-open");
        this.#search_controls_element.classList.add("is-open");
        this.#search_bar_contenteditable_element.setAttribute("contenteditable", true);
        this.#search_bar_contenteditable_element.focus();

        if (this.#nodes_to_search.length > 0) {
            this.#selected_nodes_to_search_section_element.classList.add("is-open");
        }

        this.#is_search_in_use = true;
    }

    close_search_blocks() {
        this.empty_search_bar();
        //if (this.#search_bar_contenteditable_element.innerText.trim().length === 0) {
        this.#search_bar_contenteditable_element.insertAdjacentHTML("afterbegin", templates.placeholder());

        this._is_placeholder_enabled = true;
        //}
        if (this.#isPhoneAndTabledResolution && this.#doMultiNodesSearch) {
            this.close_search_list_for_tablet_and_phone_width();
        }

        document.body.classList.remove("no-scrollable");

        this.#search_bar_contenteditable_element.setAttribute("contenteditable", false);
        this.#search_bar_contenteditable_element.classList.remove("is-open");
        this.#dropdown_element.classList.remove("is-open");
        this.#search_controls_element.classList.remove("is-open");
        this.#selected_nodes_to_search_section_element.classList.remove("is-open");
        
        this.do_dropdown_filter("");

        this.#is_search_in_use = false;
    }

    is_search_in_use() {
        return this.#is_search_in_use;
    }

    showError() {
        document.getElementById("search_bar_error").classList.add("is-open");

        setTimeout(() => {
            document.getElementById("search_bar_error").classList.add("do-close");

            setTimeout(() => {
                document.getElementById("search_bar_error").classList.remove("do-close");
                document.getElementById("search_bar_error").classList.remove("is-open");
            }, 500);
        }, 1500);
    }

    listeners() {
        this.#search_bar_contenteditable_element.addEventListener("click", (event) => {
            this.open_search_blocks();
        }, false);
        this.#search_bar_contenteditable_element.addEventListener("input", (event) => {
            this.do_dropdown_filter(event.currentTarget.innerText.trim());
        }, false);
        document.getElementById("search_bar__close").addEventListener("click", (event) => {
            this.close_search_blocks();
        }, false);

        if (this.#doMultiNodesSearch) {
            Object.keys(this.#dropdown_items_elements).map((node_name) => {
                const button_element = this.#dropdown_items_elements[node_name];

                button_element.addEventListener("click", (event) => {
                    this.add_node_to_search_list(event.currentTarget);
                }, false);
            });

            /**
             * Open & close selected nodes list to search for phone & tablet
             */
            this.#open_selected_nodes_list_button_element.addEventListener("click", (event) => {
                this.open_search_list_for_tablet_and_phone_width();
            }, false);
            this.#close_selected_nodes_list_button_element.addEventListener("click", (event) => {
                this.close_search_list_for_tablet_and_phone_width();
            }, false);

            document.getElementById("search_bar__do_search").addEventListener("click", (event) => {
                if (this.#nodes_to_search.length === 0) {
                    this.showError();

                    return;
                }

                this.close_search_blocks();
                this.dispatch_do_search_nodes_event(event.target, this.#nodes_to_search);
            }, false);

            return;
        }

        Object.keys(this.#dropdown_items_elements).map((node_name) => {
            const button_element = this.#dropdown_items_elements[node_name];

            button_element.addEventListener("click", (event) => {
                this.#search_bar_contenteditable_element.innerText = "";

                this.close_search_blocks();
                this.dispatch_do_search_nodes_event(event.target, [event.currentTarget.dataset.nodeName]);
            }, false);
        });
    }

    dispatch_do_search_nodes_event(event_target, nodes_to_search) {
        if (!Array.isArray(nodes_to_search) || nodes_to_search.length === 0) {
            throw new Error("Search_bar -dispatch_do_search_nodes_event - nodes_to_search is not an array or has no items");
        }

        const do_search_event = new CustomEvent(
            "do_search_nodes",
            {
                detail : {
                    nodes_to_search: nodes_to_search
                },
                bubbles: true
            }
        );

        event_target.dispatchEvent(do_search_event);
    }

    close_search_list_for_tablet_and_phone_width() {
        this.#open_selected_nodes_list_button_element.classList.remove("hide");
        this.#close_selected_nodes_list_button_element.classList.remove("show");
        this.#selected_nodes_to_search_section_element.querySelector("h1").classList.remove("show");
        this.#selected_nodes_to_search_list_element.classList.remove("show");
        this.#selected_nodes_to_search_section_element.classList.remove("full-width");
    }

    open_search_list_for_tablet_and_phone_width() {
        this.#isPhoneAndTabledResolution = true;

        this.#open_selected_nodes_list_button_element.classList.add("hide");
        this.#close_selected_nodes_list_button_element.classList.add("show");
        this.#selected_nodes_to_search_section_element.classList.add("full-width");
        this.#selected_nodes_to_search_section_element.querySelector("h1").classList.add("show");
        this.#selected_nodes_to_search_list_element.classList.add("show");
    }

    set_dropdown_data(dropdown_data) {
        this.#dropdown_data = dropdown_data;
    }

    do_dropdown_filter(value) {
        if (value.length === 0) {
            this.#dropdown_content_element.querySelectorAll("button.in-flow").forEach((button_element) => {
                button_element.classList.remove("in-flow");
            });

            return;
        }

        const value_to_filter = value.toUpperCase();

        this.#source_data_model_as_array.filter((node) => {
            const node_label_inside_graph = node[0];
            const node_name = node[1].name;
            const node_description = node[1].description || '';

            if (this.#dropdown_items_elements[node_label_inside_graph]) {
                if (
                    node_label_inside_graph.toUpperCase().indexOf(value_to_filter) > -1 ||
                    node_name.toUpperCase().indexOf(value_to_filter) > -1 ||
                    node_description.toUpperCase().indexOf(value_to_filter) > -1
                ) {
                    this.#dropdown_items_elements[node_label_inside_graph].classList.add("in-flow");
                } else {
                    this.#dropdown_items_elements[node_label_inside_graph].classList.remove("in-flow");
                }
            }
        });
    }

    destroy() {
        this.#dropdown_data = null;
        this.#search_bar_contenteditable_element = null;
        this.#nodes_to_search = [];
        this.#dropdown_items_elements = null;
        this.#dropdown_element = null;
        this.#search_controls_element = null;
        this.#selected_nodes_to_search_section_element = null;
        this.#selected_nodes_to_search_list_element = null;
        this.#dropdown_content_element = null;
        this.#open_selected_nodes_list_button_element = null;
        this.#close_selected_nodes_list_button_element = null;
        this.#source_data_model_as_array = [];
        this.#oNodes = null;
    }
}