import {Di} from "hind/core";
import Model from "./Model";
import Nodes from "./Nodes";
import Search_bar from "./Search_bar";
import spinner from "../../views/modules/hierarchy_tree/spinner";


let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class Hierarchy_tree {
    #cursor_svg_for_parent_node = '<path d="M0 80C0 53.5 21.5 32 48 32h96c26.5 0 48 21.5 48 48V96H384V80c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H432c-26.5 0-48-21.5-48-48V160H192v16c0 1.7-.1 3.4-.3 5L272 288h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H272c-26.5 0-48-21.5-48-48V336c0-1.7 .1-3.4 .3-5L144 224H48c-26.5 0-48-21.5-48-48V80z"/>';
    #is_node_information_popin_enabled = false;
    #oSearch_bar = null;
    #spinner_layer_element = null;
    #spinner_svg_element = null;
    #oNodes = null;
    #zoom_listener = null;
    #base_svg = null;

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
            throw "Hierarchy_tree - constructor - Cannot construct singleton";
        } else {
            this._nodes = {};
            this._treeData = null;
            this._d3 = Di.get_di("D3");

            // Set the dimensions and margins of the diagram
            this._margin = {
                top   : 20,
                right : 120,
                bottom: 20,
                left  : 175
            };
            this._width = this.get_width();
            this._height = this.get_height();
            this._svg = null;
            this._svg_hierarchy_tree_id = "rhizome_tree";
            this._graph_container_node = null;
            this._treemap = null;
            this._mapping_nodename_with_their_ways = null;
            this._root = null;
            this._cpt_node = 0;
            this._duration = this._d3.event && this._d3.event.altKey ? 5000 : 500;
            this.#oSearch_bar = Search_bar.get_instance();
            this.#oNodes = Nodes.get_instance();
        }
    }

    static get_instance() {
        // console.log("Hierarchy_tree - get instance");
        if (!this[singleton]) {
            this[singleton] = new Hierarchy_tree(singletonEnforcer);
        }

        return this[singleton];
    }

    init(configuration) {
        configuration = configuration ?? {};

        return new Promise(
            (resolve, reject) => {
                this._treeData = Model.get().hierarchy_tree_data;
                this._mapping_nodename_with_their_ways = Model.get().mapping_nodename_with_their_ways;

                this.#oSearch_bar.set_configuration(configuration.search || {});
                this.#oSearch_bar.set_dropdown_data(Object.keys(this._mapping_nodename_with_their_ways));

                resolve(true);
            }
        );
    }

    get_search_bar_instance() {
        return this.#oSearch_bar;
    }

    get_width() {
        return window.innerWidth - this._margin.left - this._margin.right;
    }

    get_height() {
        return window.innerHeight - this._margin.top - this._margin.bottom;
    }

    get_graph_height(root) {
        if (!root) {
            return 0;
        }
        let expandChildren = [0];

        if (root.children) { // if it has expanded children
            expandChildren = root.children.map(ea => this.get_graph_height(ea))
        }

        const max = expandChildren.reduce((a, b) => Math.max(a, b));

        return 1 + max;
    }

    show_spinner() {
        this.#spinner_layer_element.classList.add("show");
        this.#spinner_svg_element.classList.add("show");
    }

    hide_spinner() {
        this.#spinner_layer_element.classList.remove("show");
        this.#spinner_svg_element.classList.remove("show");
    }

    listeners() {
        const debounce = (callback, delay) => {
            let timer;

            return () => {
                clearTimeout(timer);

                timer = setTimeout(() => {
                    callback();
                }, delay)
            }
        };

        window.addEventListener("do_search_nodes", (event) => {
            this.collapse();
            this.show_spinner();
            this.move_graph(this._margin.left, this._height / 2, this._d3.zoomTransform(this.#base_svg.node()).k);

            setTimeout(() => {
                this.hide_spinner();
                this.run_search_nodes(event.detail.nodes_to_search);
            }, 1500);
        });

        window.addEventListener('resize', debounce(() => {
            this._width = this.get_width();
            this._height = this.get_height();

            document.getElementById(this._svg_hierarchy_tree_id).setAttribute("width", this._width);
            document.getElementById(this._svg_hierarchy_tree_id).setAttribute("height", this._height);
            document.querySelector(`#${this._svg_hierarchy_tree_id} > g`).setAttribute("transform", "translate("
                + this._margin.left + "," + this._height / 2 + ")");

            this.update(this._root);
        }, 500));

        document.onkeydown = (e) => {
            if (e.key === "Escape") {
                if (this.#is_node_information_popin_enabled) {
                    this.do_scrollable_body();
                    this.close_popin_node_information();
                } else if (this.#oSearch_bar.is_search_in_use()) {
                    this.#oSearch_bar.close_search_blocks();
                }
            }
        };

        document.getElementById("layer").addEventListener("click", (evt) => {
            this.do_scrollable_body();
            this.close_popin_node_information();
        }, false);
    }

    run_search_nodes(nodes_names_to_search) {
        this.toggle(this._root);

        nodes_names_to_search.forEach((node_name) => {
            const ways_to_show = this._mapping_nodename_with_their_ways[node_name] || null;

            if (ways_to_show !== null) {
                ways_to_show.forEach((way) => {
                    const levels = way.split(",");

                    let step = 0;
                    let levels_true_length = levels.length - 1;
                    let path = this._root.children;

                    levels.forEach((level) => {
                        if (step < levels_true_length) {
                            // console.log(level, step, path[level]);
                            // console.log(path[level].data.name)
                            if (!path[level].isOpen) {
                                this.toggle(path[level]);

                                document.querySelectorAll(`path.link[data-node-name="${path[level].data.name}"]`).forEach((node) => {
                                    node.classList.add("node-found");
                                });
                            }
                            // console.log(path[level]);
                            path = path[level].children || path[level]._children;
                        } else {
                            document.querySelectorAll(`path.link[data-node-name="${path[level].data.name}"]`).forEach((node) => {
                                node.classList.add("node-found");
                            });
                        }

                        step++;
                    });
                });

                document.querySelectorAll(`circle.node[data-node-name="${node_name}"]`).forEach((node) => {
                    node.classList.add("node-found");
                });
                document.querySelectorAll(`text.node-name[data-node-name="${node_name}"]`).forEach((node) => {
                    node.classList.add("node-found");
                });

                const node = this.get_d3_element_from_node_element(`circle.node[data-node-name="${node_name}"]`);

                if (node !== null) {
                    this.center_node(node);
                }
            }
        });

        if (nodes_names_to_search.length === 1 && !this.#oNodes.is_node_empty(nodes_names_to_search[0])) {
            this.load_popin_data(nodes_names_to_search[0]);
        }
    }

    get_d3_element_from_node_element(selector) {
        const node_element_selected = document.querySelector(selector);

        if (node_element_selected === null) {
            return null;
        }

        return this._d3.select(node_element_selected).select((d) => {
            return d;
        })._groups[0][0] || null;
    }

    load_popin_data(node_name) {
        this.#is_node_information_popin_enabled = true;
        document.getElementById("popin_node_information_wrapper").innerHTML = this.get_popin_data(node_name);

        this.prevent_scrollable_body();
        this.open_popin_node_information();

        document.getElementById("popin_node_information_close_button").addEventListener("click", (evt) => {
            this.close_popin_node_information();
        }, false);
    }

    get_external_link = () => {
        return `
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z"/></svg>
        `;
    };

    get_popin_data(node_name) {
        const the_node = this.#oNodes.get(node_name);

        if (!the_node) {
            throw new Error("Hierarchy_tree - get_popin_data - this node name doesn't exist in source_data_model : " + node_name);
        }

        const html_link = (the_node.url) ? `<a href="${the_node.url}" target="_blank">Page web ${this.get_external_link()}</a>` : "";

        return `
            <section id="popin_node_information" class="popin-node-information">             
                <svg id="popin_node_information_close_button" class="popin-node-information__close-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                <h1>${the_node.name}</h1>
                <hr />
                <p>${the_node.description}</p>
                ${html_link}
            </section>
        `;
    }

    prevent_scrollable_body() {
        document.body.classList.add("no-scrollable");
    }

    do_scrollable_body() {
        document.body.classList.remove("no-scrollable");
    }

    open_popin_node_information() {
        document.getElementById("layer").classList.toggle("layer--is-enabled");
        document.getElementById("popin_node_information").classList.toggle("popin-node-information--is-open");
    }

    close_popin_node_information() {
        if (this.#is_node_information_popin_enabled) {
            document.getElementById("popin_node_information").classList.toggle("popin-node-information--is-open");
            document.getElementById("layer").classList.toggle("layer--is-enabled");

            this.#is_node_information_popin_enabled = false;
        }
    }

    collapse(do_update_tree = true, show_first_level = false) {
        if (this._root.children !== null) {
            this._root.children.forEach((d) => {
                this.collapseNode(d);
            });

            if (!show_first_level) {
                this._root._children = this._root.children;
                this._root.children = null;
                this._root.isOpen = false;
            }

            if (do_update_tree) {
                this.update(this._root);
            }
        }
    }

    /**
     * collapseNode the node and all it's children
     *
     * @param {Object} d Node in hirarchy tree
     */
    collapseNode(d) {
        if (d.children) {
            d._children = d.children
            d._children.forEach((d) => {
                this.collapseNode(d);
            })
            d.children = null
            d.isOpen = false;
        }
    }

    init_spinner(selector) {
        document.querySelector(selector).insertAdjacentHTML("afterbegin", spinner());

        this.#spinner_layer_element = document.getElementById("hierarchy_tree_spinner_layer");
        this.#spinner_svg_element = document.getElementById("hierarchy_tree_spinner");
    }

    run(selector, source_data_model) {
        this.#oNodes.set_source_data_model(source_data_model);
        this.#oSearch_bar.prepare_oNodes();

        return new Promise(
            (resolve, reject) => {
                // append the svg object to the body of the page
                // appends a 'group' element to 'svg'
                // moves the 'group' element to the top left margin
                // Zoom and Drag'n'Drop SVG
                this.#zoom_listener = this._d3.zoom().scaleExtent([0.5, 3]).on('zoom', (e) => {
                    this._svg.attr('transform', e.transform);
                });

                this.#base_svg = this._d3.select(selector).append("svg")
                    .attr("id", this._svg_hierarchy_tree_id)
                    .attr("width", this._width)
                    .attr("height", this._height)
                    .call(this.#zoom_listener);

                this._svg = this.#base_svg
                    .append("g")
                    .attr("transform", "translate("
                        + this._margin.left + "," + this._height / 2 + ")");

                this._cpt_node = 0;

                // declares a tree layout and assigns the size
                this._treemap = this._d3
                    .tree()
                    // .size([this._height, this._width])
                    .nodeSize([20, 20])
                    .separation(function (a, b) {
                        return a.parent === b.parent ? 2 : 3
                    });

                // Assigns parent, children, height, depth
                this._root = this._d3.hierarchy(this._treeData, function (d) {
                    return d.children;
                });
                this._root.x0 = this._height / 2;
                this._root.y0 = 0;

                this.collapse(false, true);
                this.listeners();
                this.update(this._root);
                this.init_spinner(selector);

                resolve(true);
            }
        );
    }

    move_graph(x, y, k) {
        this._svg.transition().duration(750)
            .call(this.#zoom_listener.transform, this._d3.zoomIdentity.translate(x, y).scale(k));
    }

    update(source) {
        const current_graph_height = this.get_graph_height(this._root);

        // Assigns the x and y position for the nodes
        this._treeData = this._treemap(this._root);

        // Compute the new tree layout.
        const nodes = this._treeData.descendants(),
            links = this._treeData.descendants().slice(1);

        // Normalize for fixed-depth.
        nodes.forEach(d => {
            d.y = (d.depth === 0) ? 50 : (d.depth === 1) ? d.depth * this._width / (current_graph_height + Math.log(current_graph_height - 1)) : d.depth * 325;
        });

        // ****************** Nodes section ***************************

        // Update the nodes...
        const node = this._svg.selectAll('g.node')
            .data(nodes, (d) => {
                return d.id || (d.id = ++this._cpt_node);
            });

        // Enter any new modes at the parent's previous position.
        const nodeEnter = node.enter().append('g')
            .attr('class', 'node')
            .attr("transform", function (d) {
                return "translate(" + source.y0 + "," + source.x0 + ")";
            });

        // Add Circle for the nodes
        nodeEnter.append('circle')
            .attr('class', 'node')
            .attr('data-node-name', (d) => {
                return d.data.name;
            })
            .attr('r', 1e-6)
            .style("fill", function (d) {
                return d._children ? "lightsteelblue" : "#fff";
            })
            .style("cursor", (d) => {
                return (d._children || d.children) ? `${this.svgUrl(this.#cursor_svg_for_parent_node)}, auto` : "inherit";
            })
            .on('click', (event, d) => {
                const current_d_clicked = (d._children) ? d : null;

                this.toggle(d);

                if (current_d_clicked !== null) {
                    this.center_node(current_d_clicked);
                }
            });

        // Add labels for the nodes
        nodeEnter.append('text')
            .attr('class', (d) => {
                let node_text_class = "node-name";

                node_text_class += (this.#oNodes.is_node_empty(d.data.name)) ?
                    ''
                    :
                    ' as-link';

                return node_text_class;
            })
            .attr('data-node-name', (d) => {
                return d.data.name;
            })
            .attr("dy", ".35em")
            .attr("x", function (d) {
                return d.children || d._children ? -13 : 13;
            })
            .attr("text-anchor", function (d) {
                return d.children || d._children ? "end" : "start";
            })
            .text(function (d) {
                return d.data.name;
            })
            .on('click', (event, d) => {
                if (this.#oNodes.is_node_empty(d.data.name)) {
                    return;
                }

                this.load_popin_data(d.data.name);
            })
        ;

        // UPDATE
        const node_update = nodeEnter.merge(node);

        // Transition to the proper position for the node
        node_update.transition()
            .duration(this._duration)
            .attr("transform", function (d) {
                return "translate(" + d.y + "," + d.x + ")";
            });

        // Update the node attributes and style
        node_update.select('circle.node')
            .attr('r', 10)
            .style("fill", function (d) {
                return d._children ? "lightsteelblue" : "#fff";
            })
            .attr("pointer", (d) => {
                return d._children ? `${this.svgUrl(this.#cursor_svg_for_parent_node)}, auto` : "inherit";
            });

        // Remove any exiting nodes
        const node_exit = node.exit().transition()
            .duration(this._duration)
            .attr("transform", function (d) {
                return "translate(" + source.y + "," + source.x + ")";
            })
            .remove();

        // On exit reduce the node circles size to 0
        node_exit.select('circle')
            .attr('r', 1e-6);

        // On exit reduce the opacity of text labels
        node_exit.select('text')
            .style('fill-opacity', 1e-6);

        // ****************** links section ***************************

        // Update the links...
        const link = this._svg.selectAll('path.link')
            .data(links, function (d) {
                return d.id;
            });

        // Enter any new links at the parent's previous position.
        const linkEnter = link.enter().insert('path', "g")
            .attr("class", "link")
            .attr('data-node-name', (d) => {
                return d.data.name;
            })
            .attr('d', (d) => {
                const o = {
                    x: source.x0,
                    y: source.y0
                }
                return this.diagonal(o, o);
            });

        // UPDATE
        const linkUpdate = linkEnter.merge(link);

        // Transition back to the parent element position
        linkUpdate.transition()
            .duration(this._duration)
            .attr('d', (d) => {
                return this.diagonal(d, d.parent)
            });

        // Remove any exiting links
        const linkExit = link.exit().transition()
            .duration(this._duration)
            .attr('d', (d) => {
                var o = {
                    x: source.x,
                    y: source.y
                }
                return this.diagonal(o, o)
            })
            .remove();

        // Store the old positions for transition.
        nodes.forEach(function (d) {
            d.x0 = d.x;
            d.y0 = d.y;
        });
    }

    // Creates a curved (diagonal) path from parent to the child nodes
    diagonal(s, d) {
        return `M ${s.y} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`;
    }

    svgUrl(svgString, width = 16, height = 16, viewBoxWidth = 576, viewBoxHeight = 512) {
        viewBoxWidth = viewBoxWidth || width;
        viewBoxHeight = viewBoxHeight || width;
        return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="${width}" height="${height}" viewBox="0 0 ${viewBoxWidth} ${viewBoxHeight}">${svgString}</svg>')`;
    }

    // Toggle children on click.
    toggle(d) {
        if (d.children) {
            d.children.forEach((node) => {
                this.collapseNode(node);
            });

            d._children = d.children;
            d.children = null;
            d.isOpen = false;
        } else {
            d.children = d._children;
            d._children = null;
            d.isOpen = true;
        }

        this.update(d);
    }

    center_node(node) {
        const t = this._d3.zoomTransform(this.#base_svg.node());

        let x = -node.y0;
        let y = -node.x0;

        // console.log(x, y, t.k, this._width, this._height)
        x = x * t.k + this._width / 2;
        y = y * t.k + this._height / 2;

        // console.log(x, y)
        this._svg//.transition().duration(750)
            .call(this.#zoom_listener.transform, this._d3.zoomIdentity.translate(x, y).scale(t.k));
    }

    destroy() {
        this._treeData = null;
        this._graph_container_node = null;
        this._svg = null;
        this._d3 = null;
        this._treemap = null;
        this._root = null;
        this.#spinner_layer_element = null;
        this.#spinner_svg_element = null;
        this.#oNodes = null;
    }
}